import React from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import KickbackAgreementFilters from '../../../interfaces/filters/kickbackAgreementFilters';
interface IFilterProps {
    filters: KickbackAgreementFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    departureYear: LanguageUtils.createMessage('year'),
    departureMonth: LanguageUtils.createMessage('month'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    downloadCSV: LanguageUtils.createMessage('Download CSV')
};

export default function KickbackAgreementFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="year"
                                label={messages.departureYear}
                                onChange={onChange}
                                value={filters.year}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="month"
                                label={messages.departureMonth}
                                onChange={onChange}
                                value={filters.month}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.downloadCSV} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}