import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import Pagination from '../../../interfaces/common/pagination';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import PageUtils from '../../../utils/pageUtils';
import OrderNotYetInvoiced from '../../../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../../../interfaces/filters/orderNotYetInvoicedFilters';
import { fetchOrdersNotYetInvoiced, getOrdersNotYetInvoiced, isLoadingOrdersNotYetInvoiced, resetOrdersNotYetInvoiced } from '../../../reducers/reportsReducer';
import Paging from '../../../interfaces/common/paging';
import OrdersNotYetInvoicedFilterComponent from './ordersNotYetInvoicedFilterComponent';
import OrdersNotYetInvoicedList from './ordersNotYetInvoicedList';
import UrlConstants from '../../../constants/UrlConstants';

interface IOrderNotYetInvoicedProps {
    ordersNotInvoiced: Pagination<OrderNotYetInvoiced>;
    classes: any;
    fetchOrdersNotYetInvoiced: any;
    isLoading: boolean;
    onChange: () => void;
    resetOrdersNotYetInvoiced: () => void;
    fetch: any;
}

interface IOrderNotYetInvoicedState {
    filters: OrdersNotYetInvoicedFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    ordersNotYetInvoiced: LanguageUtils.createMessage('Orders not invoiced')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrdersNotYetInvoiced: (paging: Paging, filters: OrdersNotYetInvoicedFilters) => dispatch(fetchOrdersNotYetInvoiced({
        paging,
        filters
    })),
    resetOrdersNotYetInvoiced: () => dispatch(resetOrdersNotYetInvoiced())

});

const mapStoreToProps = (store: RootState) => {
    return {
        ordersNotInvoiced: getOrdersNotYetInvoiced(store),
        isLoading: isLoadingOrdersNotYetInvoiced(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class OrdersNotYetInvoiced extends Component<IOrderNotYetInvoicedProps, IOrderNotYetInvoicedState> {
    terminalsListRef: any;
    constructor(props: IOrderNotYetInvoicedProps) {
        super(props);
        this.terminalsListRef = React.createRef();

        this.state = {
            filters: {} as OrdersNotYetInvoicedFilters,
            paging: PageUtils.getDefaultPaging([{
                field: 'revenue',
                sort: 'desc'
            } as GridSortItem]),
            menuItem: {
                id: 1,
                name: messages.ordersNotYetInvoiced,
                icon: <FontAwesomeIcon icon="warehouse" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchOrdersNotYetInvoiced } = this.props;
        const { paging, filters } = this.state;

        fetchOrdersNotYetInvoiced(paging, filters);
    }

    componentWillUnmount() {
        const { resetOrdersNotYetInvoiced } = this.props;
        resetOrdersNotYetInvoiced();
    }

    applyFilters = () => {
        const { fetchOrdersNotYetInvoiced } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchOrdersNotYetInvoiced(newPagination, filters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as OrdersNotYetInvoicedFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchOrdersNotYetInvoiced } = this.props;
        const { paging } = this.state;

        const newFilters = {
        } as OrdersNotYetInvoicedFilters;

        const newSortModel = [{
            field: 'revenue',
            sort: 'desc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchOrdersNotYetInvoiced(newPagination, newFilters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchOrdersNotYetInvoiced } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchOrdersNotYetInvoiced(newPaging, filters);

        }
    }

    render() {
        const { classes, ordersNotInvoiced, fetchOrdersNotYetInvoiced, isLoading } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout
                menuItem={menuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
                isLoading={isLoading}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <OrdersNotYetInvoicedFilterComponent
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <OrdersNotYetInvoicedList rows={ordersNotInvoiced} isLoading={isLoading}
                                    ref={this.terminalsListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchOrdersNotYetInvoiced(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrdersNotYetInvoiced));