import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import LanguageUtils from '../../../utils/LanguageUtils';
// import WorkflowUtils from '../../../utils/workflowUtils';
import Loader from '../../common/widgets/loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrintAgreementVersionPeriodLinesOptions } from '../../../constants/printAgreementVersionPeriodLinesOptions';
import { isExportingPrintAgreementPeriodLines, exportPrintAgreementVersionPeriodLinesCSV, exportPrintAgreementVersionPeriodLines } from '../../../reducers/printAgreementVersionReducer';
import If from '../../common/widgets/if';
import PrintAgreementVersionPeriodLineFilters from '../../../interfaces/filters/printAgreementVersionPeriodLineFilters';

const messages = {
    printAgreementVersionPeriodLines: LanguageUtils.createMessage('Print agreement version period lines'),
    download: LanguageUtils.createMessage('download pdf'),
    downloadCSV: LanguageUtils.createMessage('download csv'),
    cancel: LanguageUtils.createMessage('Cancel'),
    option1: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS_1'),
    option2: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS_2'),
    option3: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS_3'),
    chooseOption: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS'),
    error: LanguageUtils.createMessage('VALIDATION_ERROR_SELECT_AN_OPTION'),
    infoMessage: LanguageUtils.createMessage('Info message to long time')
};

interface ExportPrintAgreementVersionPeriodProps {
    open: boolean;
    setOpenModal: (open: boolean) => void;
    printAgreementVersionPeriodId: number | undefined;
    printAgreementVersionPeriodLineFilters: PrintAgreementVersionPeriodLineFilters;
}

export default function ExportPrintAgreementVersionPeriod({ open, setOpenModal, printAgreementVersionPeriodId,
    printAgreementVersionPeriodLineFilters }: ExportPrintAgreementVersionPeriodProps): JSX.Element {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('2');
    const [hasError, setHasError] = React.useState(false);
    const isExporting = useSelector(isExportingPrintAgreementPeriodLines);
    const exportPdf = () => {
        if(!value) {
            setHasError(true);

            return;
        }
        dispatch(exportPrintAgreementVersionPeriodLines(
            {
                filters: {
                    ...printAgreementVersionPeriodLineFilters,
                    printAgreementDisplayOptionsId: parseInt(value, 10),
                    printAgreementVersionPeriodId: printAgreementVersionPeriodId
                }
            }));
        setOpenModal(false);
    };

    const exportCSV = () => {
        if(!value) {
            setHasError(true);

            return;
        }
        dispatch(exportPrintAgreementVersionPeriodLinesCSV(
            {
                filters: {
                    ...printAgreementVersionPeriodLineFilters,
                    printAgreementDisplayOptionsId: parseInt(value, 10),
                    printAgreementVersionPeriodId: printAgreementVersionPeriodId
                }
            }));
        setOpenModal(false);
    };
    const { formatMessage } = useIntl();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setHasError(false);
    };

    return (

        <Dialog maxWidth="md" fullWidth open={open} onClose={close}>
            <Loader isLoading={isExporting}>
                <>
                    <DialogTitle><FormattedMessage {...messages.printAgreementVersionPeriodLines} /></DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label"><FormattedMessage {...messages.chooseOption} /></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={onChange}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio checked={value === PrintAgreementVersionPeriodLinesOptions.ShowAllAvailablePricesAndSurcharges} />}
                                    label={formatMessage(messages.option1)} />
                                <FormControlLabel value="2" control={<Radio checked={value === PrintAgreementVersionPeriodLinesOptions.ShowAllAvailablePricesAndSurchargesExceptEnergySurcharge} />}
                                    label={formatMessage(messages.option2)} />
                                <FormControlLabel value="3" control={<Radio checked={value === PrintAgreementVersionPeriodLinesOptions.ShowFreightPricesWithoutSurcharge} />}
                                    label={formatMessage(messages.option3)} />
                            </RadioGroup>
                            <FormLabel color="warning" focused={true}>
                                <FormattedMessage {...messages.infoMessage} />
                            </FormLabel>
                            <If isTrue={hasError}>
                                <FormLabel error={hasError}>{formatMessage(messages.error)}</FormLabel>
                            </If>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenModal(false); setValue('2'); }}><FormattedMessage {...messages.cancel} /></Button>
                        <Button onClick={exportPdf}><FormattedMessage {...messages.download} /></Button>
                        <Button onClick={exportCSV}><FormattedMessage {...messages.downloadCSV} /></Button>
                    </DialogActions>
                </>
            </Loader>

        </Dialog>
    );
}
