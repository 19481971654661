import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import KickbackAgreementFilters from '../../../interfaces/filters/kickbackAgreementFilters';
import { exportKickbackAgreementPostenCSV, isExportKickbackAgreementPostenCSV } from '../../../reducers/reportsReducer';
import KickbackAgreementFilterComponent from './kickbackAgreementFilter';
import UrlConstants from '../../../constants/UrlConstants';

interface IKickbackAgreementCSVProps {
    classes: any;
    exportKickbackAgreementPostenCSV: any;
    isLoading: boolean;
    onChange: () => void;
    resetPrevision: () => void;
    fetch: any;
}

interface IKickBackAgreementCSVState {
    filters: KickbackAgreementFilters;
    menuItem: IMenuItem;
}

const messages = {
    kickBackAgreement: LanguageUtils.createMessage('Kickback Agreement')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exportKickbackAgreementPostenCSV: (filters: KickbackAgreementFilters) => dispatch(exportKickbackAgreementPostenCSV({
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        isLoading: isExportKickbackAgreementPostenCSV(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class KickbackAgreementCSV extends Component<IKickbackAgreementCSVProps, IKickBackAgreementCSVState> {
    terminalsListRef: any;
    constructor(props: IKickbackAgreementCSVProps) {
        super(props);

        this.state = {
            filters: {} as KickbackAgreementFilters,
            menuItem: {
                id: 1,
                name: messages.kickBackAgreement,
                icon: <FontAwesomeIcon icon="fan-table" size="1x" transform="grow-7" />,
                isSelected: false
            }
        };
    }

    applyFilters = () => {
        const { exportKickbackAgreementPostenCSV } = this.props;
        const { filters } = this.state;

        exportKickbackAgreementPostenCSV(filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters } = this.state;

        const newFilter = { ...filters } as KickbackAgreementFilters;
        (newFilter as any)[attribute] = value;

        this.setState({
            filters: newFilter
        });
    }

    setDefaultState = () => {
        const newFilters = {
        } as KickbackAgreementFilters;

        this.setState({
            filters: newFilters
        });
    }

    render() {
        const { classes, isLoading } = this.props;
        const { filters, menuItem } = this.state;

        return (
            <MainLayout menuItem={menuItem}
                isLoading={isLoading}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
            >
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <KickbackAgreementFilterComponent
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(KickbackAgreementCSV));