import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import Pagination from '../../../interfaces/common/pagination';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import PageUtils from '../../../utils/pageUtils';
import OrderNotYetInvoiced from '../../../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../../../interfaces/filters/orderNotYetInvoicedFilters';
import { fetchOrdersNotYetInvoicedFullReport, getOrdersNotYetInvoicedFullReport, isLoadingOrdersNotYetInvoicedFullReport, resetOrdersNotYetInvoicedFullReport } from '../../../reducers/reportsReducer';
import Paging from '../../../interfaces/common/paging';
import OrdersNotYetInvoicedFullReportList from './ordersNotYetInvoicedFullReportList';
import OrdersNotYetInvoicedFullReportFilterComponent from './ordersNotYetInvoicedFullReportFilterComponent';
import UrlConstants from '../../../constants/UrlConstants';
interface IOrderNotYetInvoicedFullReportProps {
    ordersNotInvoiced: Pagination<OrderNotYetInvoiced>;
    classes: any;
    fetchOrdersNotYetInvoicedFullReport: any;
    isLoading: boolean;
    onChange: () => void;
    resetOrdersNotYetInvoicedFullReport: () => void;
    fetch: any;
    match: any;
}

interface IOrderNotYetInvoicedFullReportState {
    filters: OrdersNotYetInvoicedFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    ordersNotYetInvoiced: LanguageUtils.createMessage('Orders not invoiced'),
    ordersNotYetInvoicedFullReport: LanguageUtils.createMessage('Orders not invoiced full report')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrdersNotYetInvoicedFullReport: (paging: Paging, filters: OrdersNotYetInvoicedFilters) => dispatch(fetchOrdersNotYetInvoicedFullReport({
        paging,
        filters
    })),
    resetOrdersNotYetInvoicedFullReport: () => dispatch(resetOrdersNotYetInvoicedFullReport())

});

const mapStoreToProps = (store: RootState) => {
    return {
        ordersNotInvoiced: getOrdersNotYetInvoicedFullReport(store),
        isLoading: isLoadingOrdersNotYetInvoicedFullReport(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class OrdersNotYetInvoicedFullReport extends Component<IOrderNotYetInvoicedFullReportProps, IOrderNotYetInvoicedFullReportState> {
    terminalsListRef: any;
    constructor(props: IOrderNotYetInvoicedFullReportProps) {
        super(props);
        this.terminalsListRef = React.createRef();

        this.state = {
            filters: {} as OrdersNotYetInvoicedFilters,
            paging: PageUtils.getDefaultPaging([{
                field: 'revenue',
                sort: 'desc'
            } as GridSortItem]),
            menuItem: {
                id: 1,
                name: messages.ordersNotYetInvoicedFullReport,
                icon: <FontAwesomeIcon icon="warehouse" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchOrdersNotYetInvoicedFullReport } = this.props;
        const { customerId, month, year } = this.props.match.params;
        const { paging, filters } = this.state;
        const newFilter = {
            ...filters,
            customerId: customerId,
            departureMonth: month,
            departureYear: year
        } as OrdersNotYetInvoicedFilters;

        fetchOrdersNotYetInvoicedFullReport(paging, newFilter);
    }

    componentWillUnmount() {
        const { resetOrdersNotYetInvoicedFullReport } = this.props;
        resetOrdersNotYetInvoicedFullReport();
    }

    applyFilters = () => {
        const { fetchOrdersNotYetInvoicedFullReport } = this.props;
        const { paging, filters } = this.state;
        const { customerId, month, year } = this.props.match.params;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        const newFilter = {
            ...filters,
            customerId: customerId,
            departureMonth: month,
            departureYear: year
        } as OrdersNotYetInvoicedFilters;

        this.setState({
            paging: newPagination,
            filters: newFilter
        });

        fetchOrdersNotYetInvoicedFullReport(newPagination, newFilter);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;
        const { customerId, month, year } = this.props.match.params;

        const newFilter = {
            ...filters,
            customerId: customerId,
            departureMonth: month,
            departureYear: year
        } as OrdersNotYetInvoicedFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchOrdersNotYetInvoicedFullReport } = this.props;
        const { paging } = this.state;
        const { customerId, month, year } = this.props.match.params;
        const newFilters = {
            customerId: customerId,
            departureMonth: month,
            departureYear: year
        } as OrdersNotYetInvoicedFilters;

        const newSortModel = [{
            field: 'revenue',
            sort: 'desc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchOrdersNotYetInvoicedFullReport(newPagination, newFilters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchOrdersNotYetInvoicedFullReport } = this.props;
        const { sortModel, filters, paging } = this.state;
        const { customerId, month, year } = this.props.match.params;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };
            const newFilter = {
                ...filters,
                customerId: customerId,
                departureMonth: month,
                departureYear: year
            } as OrdersNotYetInvoicedFilters;

            this.setState({
                sortModel: newModel,
                paging: newPaging,
                filters: newFilter
            });

            fetchOrdersNotYetInvoicedFullReport(newPaging, newFilter);

        }
    }

    render() {
        const { classes, ordersNotInvoiced, fetchOrdersNotYetInvoicedFullReport, isLoading } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;
        const { customerId, month, year } = this.props.match.params;

        return (
            <MainLayout menuItem={menuItem}
                isLoading={isLoading}
                routes={
                    [{
                        name: 'go to prev page',
                        url: `${UrlConstants.ORDER_NOT_YET_INVOICED}`
                    }]
                }
            >
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <OrdersNotYetInvoicedFullReportFilterComponent
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <OrdersNotYetInvoicedFullReportList rows={ordersNotInvoiced} isLoading={isLoading}
                                    ref={this.terminalsListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        const newFilter = {
                                            ...filters,
                                            customerId: customerId,
                                            departureMonth: month,
                                            departureYear: year

                                        } as OrdersNotYetInvoicedFilters;
                                        fetchOrdersNotYetInvoicedFullReport(newPage, newFilter);
                                        this.setState({
                                            paging: newPage,
                                            filters: newFilter
                                        });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrdersNotYetInvoicedFullReport));