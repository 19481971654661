import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import ReportsApi from '../api/reportsApi';
import { exportKickbackAgreementPostenCSVError, exportKickbackAgreementPostenCSVSuccess,
    exportProvisionsCSVError, exportProvisionsCSVSuccess, fetchCalculatedPriceError,
    fetchCalculatedPriceSuccess, fetchOrdersNotYetInvoicedError,
    fetchOrdersNotYetInvoicedFullReportError,
    fetchOrdersNotYetInvoicedFullReportSuccess,
    fetchOrdersNotYetInvoicedSuccess } from '../reducers/reportsReducer';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import CalculatedPriceFilters from '../interfaces/filters/calculatedPriceFilters';
import CalculatedPrice from '../interfaces/output/calculatedPrice';
import FileWithName from '../interfaces/common/FileWithName';
import FileSaver from 'file-saver';
import ProvisionFilters from '../interfaces/filters/kickbackAgreementFilters';

function* doFetchOrdersNotYetInvoiced(action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<OrderNotYetInvoiced> = yield call(ReportsApi.fetchOrdersNotYetInvoiced, paging, filters);
        yield put(fetchOrdersNotYetInvoicedSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersNotYetInvoicedError(error as HttpErrorResponse));
    }
}

function* doFetchOrdersNotYetInvoicedFullReport(action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<OrderNotYetInvoiced> = yield call(ReportsApi.fetchOrdersNotYetInvoicedFullReport, paging, filters);
        yield put(fetchOrdersNotYetInvoicedFullReportSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersNotYetInvoicedFullReportError(error as HttpErrorResponse));
    }
}

function* doFetchCalculatedPrice(action: PayloadAction<{ paging: Paging, filters?: CalculatedPriceFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CalculatedPrice> = yield call(ReportsApi.fetchCalculatedPrice, paging, filters);
        yield put(fetchCalculatedPriceSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalculatedPriceError(error as HttpErrorResponse));
    }
}

function* doExportProvisionsCSV(action: PayloadAction<{ searchTerm?: string }>) {
    const { searchTerm } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(ReportsApi.exportProvisionsCSV, searchTerm);
        FileSaver.saveAs(data, name);
        yield put(exportProvisionsCSVSuccess());
    }

    catch (error: unknown) {
        yield put(exportProvisionsCSVError(error as HttpErrorResponse));
    }
}

function* doExportKickbackAgreementPostenCSV(action: PayloadAction<{ filters?: ProvisionFilters }>) {
    const { filters } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(ReportsApi.exportKickbackAgreementPostenCSV, filters);
        FileSaver.saveAs(data, name);
        yield put(exportKickbackAgreementPostenCSVSuccess());
    }

    catch (error: unknown) {
        yield put(exportKickbackAgreementPostenCSVError(error as HttpErrorResponse));
    }
}
export default [
    takeLatest('reports/fetchOrdersNotYetInvoiced', doFetchOrdersNotYetInvoiced),
    takeLatest('reports/fetchOrdersNotYetInvoicedFullReport', doFetchOrdersNotYetInvoicedFullReport),
    takeLatest('reports/fetchCalculatedPrice', doFetchCalculatedPrice),
    takeLatest('reports/exportProvisionsCSV', doExportProvisionsCSV),
    takeLatest('reports/exportKickbackAgreementPostenCSV', doExportKickbackAgreementPostenCSV)
];

