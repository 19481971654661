import { Theme, Grid, Container, Button } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import { exportProvisionsCSV, isExportProvisionsCSV } from '../../../reducers/reportsReducer';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import { FormattedMessage } from 'react-intl';
import UrlConstants from '../../../constants/UrlConstants';

interface IProvisionCSVProps {
    classes: any;
    exportProvisionsCSV: any;
    isLoading: boolean;
    onChange: () => void;
    fetch: any;
}

interface IProvisionCSVState {
    searchTerm: string;
    menuItem: IMenuItem;
}

const messages = {
    prevision: LanguageUtils.createMessage('Provisions'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    downloadCSV: LanguageUtils.createMessage('Download CSV')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exportProvisionsCSV: (searchTerm: string) => dispatch(exportProvisionsCSV({
        searchTerm
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        isLoading: isExportProvisionsCSV(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class ProvisionCSV extends Component<IProvisionCSVProps, IProvisionCSVState> {
    terminalsListRef: any;
    constructor(props: IProvisionCSVProps) {
        super(props);
        this.terminalsListRef = React.createRef();

        this.state = {
            searchTerm: '',
            menuItem: {
                id: 1,
                name: messages.prevision,
                icon: <FontAwesomeIcon icon="table" size="1x" transform="grow-7" />,
                isSelected: false
            }
        };
    }

    applyFilters = () => {
        const { exportProvisionsCSV } = this.props;
        const { searchTerm } = this.state;

        exportProvisionsCSV(searchTerm);
    }

    onChange = (value: any) => {

        this.setState({
            searchTerm: value
        });
    }

    setDefaultState = () => {
        this.setState({
            searchTerm: ''
        });
    }

    onChangeQuickSearch = (value: any) => {
        this.setState({
            searchTerm: value
        });
    };

    render() {
        const { classes, isLoading } = this.props;
        const { searchTerm, menuItem } = this.state;

        return (
            <MainLayout
                menuItem={menuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
                isLoading={isLoading}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <QuickSearchToolbar
                                searchTerm={searchTerm}
                                onSearch={this.onChangeQuickSearch}
                            />
                            <Grid item>
                                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                                    <Grid item>
                                        <Button onClick={this.setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={this.applyFilters}><FormattedMessage {...messages.downloadCSV} /></Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ProvisionCSV));