import React from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import { Theme } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';

const messages = {
    ordersNotYetInvoiced: LanguageUtils.createMessage('Orders not invoiced'),
    provisions: LanguageUtils.createMessage('Provisions'),
    kickbackAgreement: LanguageUtils.createMessage('kickback Agreement'),
    reports: LanguageUtils.createMessage('Reports')

};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(4)
        },
        link: {
            textDecoration: 'none',
            color: `${theme.palette.info.main}!important`,
            fontSize: '12px!important'
        },
        typography: {
            fontSize: '8.5px!important',
            textTransform: 'uppercase',
            color: 'black'
        }
    })
);

export default function Reports(): JSX.Element {

    const { formatMessage } = useIntl();
    const classes = useStyles();

    return (
        <MainLayout
            menuItem={{} as IMenuItem}
        >
            <Container maxWidth="xl" className={classes.container}>
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Button color="info" component={NavLink} exact
                                to={`/${UrlConstants.ORDER_NOT_YET_INVOICED}`} title={formatMessage(messages.ordersNotYetInvoiced)}
                            >
                                <MenuItem>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item>
                                            <FontAwesomeIcon icon="warehouse" size="3x" transform="shrink-2" />
                                        </Grid>
                                        <Typography variant="caption" textAlign="center" className={classes.typography}>
                                            <FormattedMessage {...messages.ordersNotYetInvoiced} /></Typography>
                                    </Grid>
                                </MenuItem>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="info" component={NavLink} exact
                                to={`/${UrlConstants.PROVISIONS}`} title={formatMessage(messages.provisions)}
                            >
                                <MenuItem>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item>
                                            <FontAwesomeIcon icon="table" size="3x" transform="shrink-2" />
                                        </Grid>
                                        <Typography variant="caption" textAlign="center" className={classes.typography}>
                                            <FormattedMessage {...messages.provisions} /></Typography>
                                    </Grid>
                                </MenuItem>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="info" component={NavLink} exact
                                to={`/${UrlConstants.KICKBACK_AGREEMENT_POSTEN}`} title={formatMessage(messages.kickbackAgreement)}
                            >
                                <MenuItem>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item>
                                            <FontAwesomeIcon icon="chart-bar" size="3x" transform="shrink-2" />
                                        </Grid>
                                        <Typography variant="caption" textAlign="center" className={classes.typography}>
                                            <FormattedMessage {...messages.kickbackAgreement} /></Typography>
                                    </Grid>
                                </MenuItem>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </MainLayout>
    );
}
